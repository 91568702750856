main.videography{
    flex-direction: column;
    .landingPage{
        height: 60vh;
        margin-bottom: 10vh !important;
    }
    .row{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;
        margin: 0;
        overflow-x: hidden;
        .column{
            margin: 0 auto;
            min-width: 300px;
            width: 80%;
            @media only screen and (min-width: 720px) {
                width: 40%;
            }
            @media only screen and (min-width: 1200px) {
                width: 30%;
            }
            video{
                margin: 0 auto 10vh;
            }
        }
    }
    > p{
        margin-left: auto;
        margin-right: auto; //10%;
        text-align: center;
    }
}
.portfolio,
.videography{
    .description{
        width: 90%;
        margin: 0 auto 1.5em;
        text-align: center;
        max-width: 768px;
        @media only screen and (min-width: 720px) {
            width: 60%;
        }
    }
    .albumHeader{
        text-align:center;
        margin-bottom: 10vh;
        & + .content{
            text-align: center;
            > section{
                margin-bottom: 5vh;
            }
        }
    }
}

*{
    padding:0;
    margin: 0;
}

.adminNavButton{
    cursor: pointer;
    font-size: 32px;
    margin-top: 5px;
    margin-left: 5px;
}

.adminNav {
    z-index: 100;
    height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: .3s ease all;
    transition: .3s ease all;
    background-color: rgba(0, 0, 0, 0.95);
    width: 200px;
    border-right: 1px solid black;
    &.open {
        -webkit-transform: translateX(300px);
        transform: translateX(300px);
    }
    > svg{
        color: #fff;
    }
    ul {
        //height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 min(10px, 10%);
        margin-bottom: 0;
        margin-top: 20px;

        > img {
            width: 100%;
            margin: 0 auto 50px;
            filter: invert(1);
            max-width: 200px;
        }
        li {
            margin: 0 0 10px;
            list-style: none;
            align-items: center;

            &:last-child {
                margin-top: 50px;
            }
            a,p{
                color: #fff !important;
            }
            a {
                display: grid;
                grid-template-columns: 30px auto;

                &:last-child {
                    margin-bottom: 0;
                }

                .navIcon {
                    margin: 0 auto;
                    font-size: 20px;
                    color: white;
                }

                > p {
                    font-size: 16px;
                    margin: 0;
                    margin-left: 6px;
                }
            }
        }
    }
}

// #root{
//     flex-direction: row !important;
// }
// .navigationBar{
/*
.adminNav{
    padding-top: 50px;
    max-width: 30%;
    height: 100vh;
    top: 0;
    position: sticky;
    width: max-content;
    border-right: 1px solid black;
    background-color: rgba(0, 0, 0, 0.4);
    ul{
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 min(10px, 10%);
        margin-bottom: 0;
        >img{
            width: 100%;
            margin: 0 auto;
            filter: invert(1);
            max-width: 200px;
            margin-bottom: 50px;
        }
        li {
            margin: 0 0 10px;
            list-style: none;
            align-items: center;
            &:last-child{
                margin-top: auto;
            }
            a{
                display: grid;
                grid-template-columns: 30px auto;
                &:last-child{
                    margin-bottom: 0;
                }
                .navIcon{
                    margin: 0 auto;
                    font-size: 20px;
                    color: white;
                }
                > p{
                    font-size: 16px;
                    margin: 0;
                    margin-left: 6px;
                }
            }
        }
    }
}
 */
main{
    &.admin{
        flex-direction: row;
        padding-top: 50px;
        margin: 0 auto 0;
        input:focus, button:focus, textarea:focus{
            outline:none !important;
            //background-color: #fff;
            border-color: black;
            outline: 0;
            box-shadow: none !important;
        }
        .content{
            display:flex;
            margin: auto;
            flex-wrap: wrap;
            width: 100%;
            justify-content: space-around;
            .widget{
                &.daily{
                    width: 100%;
                    max-width: 1024px;
                    margin: 0 auto;
                }
                h4{
                    text-align: center;
                }
                > div{
                    max-height: 50vh;
                    width:100%;
                }
            }
        }
    }
}

.adminNavContainer{
    display:flex;
    position: fixed;
    h4{
        text-transform: capitalize;
        padding-left: 10px;
    }
}

@import "../../css/colours";

footer{
    // display:block;// !important;
    display:flex;// !important;
    flex-direction: column;
    // justify-content:space-between;
    padding: 3em 1em;
    // max-height: 40vh;
    background-color: rgba(0,0,0,1);
    // * {color: $grey;}
    width: 100%;
    // max-width: 720px;
    z-index: 1;
    .top,
    .bottom{
        width: 100%;
    }
    @media only screen and (min-width: 1200px) {
        .top,
        .bottom{
            width: 60%;
            margin: 0 auto;
            max-width: 1200px;
        }
    }
    .divider{
        height: 1px;
        width: 100%;
        margin-top: 2em;
        margin-bottom: 1em;
    }
    .subTitle{
        text-transform:uppercase;
        color:white;
        cursor: default;
        & ~ a { color: $grey !important }
    }
    .top{
        display:flex;
        flex-wrap: wrap;
        .afs{
            //flex: 2;
            padding: 0 2em 1em 0;
            img{
                width: 25%;
                filter: invert(1);
                max-width: 120px;
                max-height: 60px;
            }
            .textContainer{
                margin: 1em 0;
                width: 100%;
                p{
                    margin-bottom: 0;
                    color: $grey !important;
                    //width: max-content;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -ms-word-break: break-all;
                    word-break: break-word;
                    -ms-hyphens: auto;
                    -moz-hyphens: auto;
                    -webkit-hyphens: auto;
                    hyphens: auto;
                     //word-wrap: break-word;
                     //white-space: pre-wrap;
                     //word-break: break-word;
                }
            }

        }
        > article{
            display:flex;
            flex: 2;
            .services,
            .links{
                display: flex;
                flex-direction: column;
                width: max-content;
                flex: 1.5;
            }
        }
    }
    .bottom{
        display:flex;
        justify-content: center;
        a,p{
            color: $grey !important;
        }
        .poweredBy{
            display:flex;
            flex-wrap: wrap;
            > p{
                margin-right: 5px;
                &:first-child{
                    margin-right: 2em;
                }
            }
            > article{
                display:flex;
                p{
                    margin-bottom: 0;
                    margin-right: .25em;
                }
            }
        }
    }
}
.admin + footer{
    display: none;
}

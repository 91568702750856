@import "./colours";

@font-face {
    font-family: 'Arima Madurai';
    src: url('../fonts/ArimaMadurai-Light.woff2') format('woff2'),
        url('../fonts/ArimaMadurai-Light.woff') format('woff'),
        url('../fonts/ArimaMadurai-Light.ttf');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Josefin Sans';
    src: url('../fonts/JosefinSans-Regular.eot');
    src: url('../fonts/JosefinSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/JosefinSans-Regular.woff2') format('woff2'),
        url('../fonts/JosefinSans-Regular.woff') format('woff'),
        url('../fonts/JosefinSans-Regular.ttf') format('truetype'),
        url('../fonts/JosefinSans-Regular.svg#JosefinSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


*{
    padding:0;
    margin: 0;
    font-family: 'Josefin Sans' !important;
    text-decoration: none;
}

div.pagination a {
    padding: 0.3em 0.5em;
    border: 1px solid black;
    &.active {
        background-color: black;
        color: white !important;
    }
}

h1,h2,h3,h4,h5{
    color:black;
}

h1{
    font-size:70px !important;
}
h2{
    font-size:60px !important;
}
h3{
    font-size:50px !important;
}
h4{
    font-size:40px !important;
}
h5{
    font-size:30px !important;
}
h6{
    font-size:20px !important;
}

main.about,
main.portfolio,
main.videography,
main.contact{
    .landingPage{
        height:60vh;
        margin-bottom: 20vh;
        align-items: flex-start;
        padding-top: 30vh;
        position: relative;
        margin-top:0;
        &::before {
            -webkit-backdrop-filter: blur(2px) brightness(0.8); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
            // -webkit-backdrop-filter: blur(5px) brightness(0.95); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
            backdrop-filter: blur(2px) brightness(0.8); /* Supported in Chrome 76 */
            // backdrop-filter: blur(5px) brightness(0.95); /* Supported in Chrome 76 */

            content: "";
            display: block;
            position: absolute;
            width: 100%; height: 100%;
            top: 0;
          }
        .subNavigation{
            padding-left: 3em;
            z-index: 1;
            h3{
                text-transform: uppercase;
                color: #fff;
            }
            section{
                display:flex;
                color: $grey !important;
                .currentAlbum{
                    cursor: default;
                    color: #fff;
                    margin-left: .5em;
                    text-transform: capitalize;
                }
                p{
                    color: #fff !important;
                    margin-left: .5em;
                }
                a{
                    margin-right: .5em;
                    color: $grey !important
                }
            }
        }
    }
}

//.main.videography{
//    .landingPage{
//        margin-bottom: 10vh !important;
//    }
//}

.loadingGif{
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &:after {
        content: " ";
        display: block;
        width: 64px;
        height: 64px;
        margin: 8px;
        border-radius: 50%;
        border: 6px solid red;
        border-color: red transparent red transparent;
        animation: loadingGifAnimation 1.2s linear infinite;
    }
}

@-webkit-keyframes arlo-scaleout {
    0% {
      -webkit-transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }

  @keyframes arlo-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0;
    }
}

.landingPage{
    // This will be finalised once
    // content is received
    min-height: 400px;
    max-height: 60vh;
    height: 100%;
    display:flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
    justify-content: center;
    width:100%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    // pointer-events: none;
    @media only screen and (max-width: 480px) {
        min-height: 0;
        overflow-x: hidden;
    }
    video{
        width:100%;
        transform: scale(1.3);
        margin-top: 1em;
        @media only screen and (min-width: 480px) {
            transform: scale(1);
            margin-top:0;
        }
    }

}
.nonClickable{
    cursor: default;
    // &::before{
    //     height:0 !important;
    // }
}

.bold{
    font-weight: bold;
}
.loadingSpinner{
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: arlo-scaleout 1.0s infinite ease-in-out;
    animation: arlo-scaleout 1.0s infinite ease-in-out;
}

.loadingSpinnerWrap{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 33333;
}

.preloaderWrapper{
    &.loaded .preloaderScreen{
        opacity: 0;
        visibility: hidden;
        &::before,&::after{
            height: 0;
        }
    }
}

.preloaderScreen{
    position: fixed;
    background-color: #fff;
    z-index: 999;
    height: 100%;
    width: 100%;
    top: 0;
    left:0;
    -webkit-transition: .2s all ease;
    -o-transition: .2s all ease;
    transition: .2s all ease;
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
    &:before{
        top: 0;
        left:0;
    }
    &:after{
        bottom: 0;
        left: 0;
    }
    &:before,
    &:after{
        content: "";
        position: absolute;
        height: 50%;
        width: 100%;
        background-color: #000;
        -webkit-transition: .7s all ease;
        -o-transition: .7s all ease;
        transition: .7s all ease;
    }
}

// @keyframes shineOld{
//     10% {
//       opacity: 1;
//       top: -30%;
//       left: -30%;
//       transition-property: left, top, opacity;
//       transition-duration: 0.7s, 0.7s, 0.15s;
//       transition-timing-function: ease;
//     }
//     100% {
//       opacity: 0;
//       top: -30%;
//       left: -30%;
//       transition-property: left, top, opacity;
//     }
//   }

.admin form .action{
    margin-top: 1em;
    display:block;
}

.invitationContainer .action,
.pendingInvitation .action,
form[name='fileUpload'] input[type='submit'].action,
.admin button.action,
input[type='button'].action {
    padding: 0.5em 1em;
    color: black;
    border: 1px solid black;
    border-radius: 4px;
    background-color: white;
    &:not(:disabled):hover {
        -webkit-transition: 0.7s all ease;
        -o-transition: 0.7s all ease;
        transition: 0.7s all ease;
    }
    &.c-default:not(:disabled):hover {
        background-color: black;
        color: white;
    }
    &.c-green:not(:disabled):hover {
        background-color: #2ECC40;
        color: white;
    }
    &.c-red:not(:disabled):hover {
        background-color: #FF4136;
        color: white;
    }
}
input[type='button'].action:disabled {
    color: grey;
    border: 1px solid grey;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}
@keyframes loadingGifAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

.divider{
    background-color: #a9a9a9;
    height: 2px;
    width:80%;
    margin: 0 auto;
}

.verticalDivider{
    width: 2px;
    height: 80%;
    background-color: #a9a9a9;
}

html{
    height: 100%;
}
body{
    height: 100%;
}

#root{
    display: flex;
    flex: 1;
    min-height: 100%;
}

//#root,
#root > section{
    //height:100%;
}

// body{
//     background-image: url("../assets/background.jpg");
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-attachment: fixed;
// }

.notFound{
    text-align: center;
}

#root{
    > section{
        display:flex;
        width: 100%;
        flex-direction: column;
        background: white;
        &.adminPanel{
            display:flex;
            flex-direction: row;
        }
    }
    a,p{
        color:black;
    }
    main{
        display:flex;
        flex: 1;
        &.terms,
        &.privacy{
            flex-direction: column;
            justify-content:center;
            align-items: center;
        }
    }
}

.pendingInvitation{
    border-top: 1px solid $grey;
    margin-top: 1em;
    padding-top:1em;
}
@import "../../css/colours";
.socials{
    a{
        margin-right: 1em;
        &:last-child{
            margin-right:0;
        }
    }
    svg{
        color: $grey !important;
        font-size:16px;
    }
}
@keyframes bounce {
    0%,
    50%,
    100%{
        transform: translateY(0);
    }
    30%  { transform: translateY(-10px); }
}

@keyframes shine{
    10% {
        opacity: 1;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
        transition-duration: 0.7s, 0.7s, 0.15s;
        transition-timing-function: ease;
    }
    100% {
        opacity: 0;
        top: -30%;
        left: -30%;
        transition-property: left, top, opacity;
    }
}

@keyframes slide {
    0% {
        left: -100;
        top: 0;
    }
    50% {
        left: 120px;
        top: 0px;
    }
    100% {
        left: 290px;
        top: 0;
    }
}
@keyframes outAndBack {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.callToAction {
    position: relative;
    display:inline-block;
    overflow: hidden;
    transition: outAndBack 600ms;
    &:hover {
        transition: outAndBack 600ms;
        animation: outAndBack 600ms ease-in-out;
        &::before {
            transition: 600ms;
            content: '';
            position: absolute;
            top: 0;
            left: -100px;
            width: 70px;
            height: 100%;
            background: rgba(255, 255, 255, 0.3);
            transform: skewX(-30deg);
            animation-name: slide;
            animation: shine 600ms ease-in-out;
            animation-direction: alternate;
            background: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0.02) 0%,
                    rgba(0, 0, 0, 0.13) 56%,
                    rgba(0, 0, 0, 0.4) 72%,
                    rgba(0, 0, 0, 0) 100%
            );
        }
    }
}
.buttonBorder{
    background-color: white;
    border: 2px solid black;
    padding: 4px 8px;
    text-decoration: none !important;
    font-weight: bold;
}

.backToTop{
    cursor:pointer;
    display:none;
    transition: 300ms;
    &:hover{
        animation: bounce 1s linear infinite;
        transition: 300ms;
    }
    &.scrolled{
        @extend .buttonBorder;
        padding:0;
        display:flex;
        justify-content:space-between;
        align-items:center;
        position: fixed;
        bottom: 10px;
        right:10px;
        width: 40px;
        height: 40px;
        z-index: 10;
    }
    svg{
        margin: auto;
        font-size: 1.5em;
    }
}

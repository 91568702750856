.selectDropdown {
    position: relative;
    background-color: #E6E6E6;
    border-radius: 4px;
    margin-bottom: 1em;
    width: 100%;
    * {
        margin: 0;
        padding: 0;
        position: relative;
        box-sizing: border-box;
    }

    select {
        font-size: 1rem;
        font-weight: normal;
        max-width: 100%;
        padding: 8px 24px 8px 10px;
        border: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        &:active, &:focus {
            outline: none;
            box-shadow: none;
        }

    }
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 0;
        height: 0;
        margin-top: -2px;
        border-top: 5px solid #aaa;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
}
.admin{
    width: 100%;
}

.fileContainer{
    display:flex;
    cursor: pointer;
    padding: 4px 8px;
    align-items: center;
    justify-content: center;
    height: 100%;
    input{
        display: none;
    }
    span{
        margin-left: 8px;
    }
    svg{width:max-content}
}

.photoUpload{
    flex-direction: row;
    padding-top: 50px;
    margin: 0 auto 0;
    form{
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &, form{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        label{
            height: 50%;
            background: rgba(201, 184, 201, 0.3);
            width: 100%;
            border: dashed 4px rgba(201, 184, 201, 0.3);
            > div{
                display:flex;
                flex-wrap: wrap;
                span{
                    max-width: none !important;
                    font-size: 1em;
                }
            }
        }
        svg{
            path{
                fill: black;
            }
        }
    }
}

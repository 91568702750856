.home{
    display: flex;
    flex-direction: column;
    section{
        p,a{
            font-size:24px;
        }
        &.landingPage{
            img{
                filter: invert(1);
                max-width: 600px;
                width:35%;
                margin-bottom:2.5em;
            }
            h5{
                &:first-of-type{
                    margin-top:.5em;
                    margin-right: 25%;
                }

                &:last-child{
                    margin-left: 25%;
                }
                color:white;
            }
        }
        &.services{
            > p{
                margin-top: 1.5em;
            }
        }
        &.homeContainer{
            width: 100%;
            display:flex;
            flex-wrap:wrap;
            justify-content: space-around;
            @media only screen and (min-width: 720px) {
                width: 80%;
                margin: 0 auto;
            }
            @media only screen and (min-width: 1200px) {
                width: 70%;
            }
            h3{
                width:100%;
                margin: 0 auto;
                text-align: center;
                // font-weight: bold;
                & ~ p{
                    text-align: center;
                }
            }
            > article{
                text-align: center;
                a{
                    &:nth-child(4),
                    &:nth-child(5){
                        display:none;
                    }
                    svg{
                        height: 30px;
                        width: auto;
                    }
                }
                > section{
                    margin: 150px auto 150px;
                    > h3{
                        margin-bottom: 50px;
                    }
                }
            }
            .servicesContainer,
            & aside{
                display:flex;
                justify-content: space-around;
                flex-wrap: wrap;
                > aside{
                    flex-direction: column;
                    text-align: center;
                    // transform: skew(-10deg) rotate(2deg);
                }
            }
        }
    }
}

.shop{
    width:100%;
    section{
        > h4{
            width:100%;
            margin-top:.5em;
        }
        &.hide{
            display:none;
            // height: 0;
        }
        &.onHidden{
            border: 1px solid black;
            padding: 6px;
            margin-bottom: 6px;
            display:flex;
            justify-content:space-between;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.05);
            &.hide{
                display:none;
            }
            p{
                margin:0;
            }
            &:hover{
                cursor: pointer;
            }
        }
        .allSelected{
            text-align: center;
            margin: 0 auto;
        }
        &.cart{
            display:flex;
            align-items:center;
            justify-content: flex-end;
            padding-right: 12px;
            font-size:1.1em;
            width: max-content;
            margin-left: auto;
            position: relative;
            p{
                font-size: 1.25em;
                margin: 0 4px 0 0;
                &.notice{
                    font-size:1em;
                    text-align: left;
                    margin-bottom: 1em;
                }
            }
            svg {
                font-size: 1.5em;
            }
            &~ .cartDetails{
                max-width:200px;
                margin-left: auto;
            }
            .dropdownItems{
                top: 1.5em;
                left: auto;
                right: 0;
                width: auto;
                min-width: 300px;
                background-color:white;
                border: 1px solid grey;
                box-shadow: 0 0 16px #212121;
                text-align: right;
                li{
                    list-style-type: none;
                    display:flex;
                    span{
                        flex:1;
                        text-align: center;
                        &:first-child{
                            text-align: left;
                        }
                    }
                }
            }
        }
        &.imageSelectorContainer{
            .imageContainer{
            display:flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width:100%;
            max-width: 1200px;
            margin: 0 auto;
            article{
                &.imageContainer{
                    min-width: 200px;
                    width: 20%;
                    height: max-content;
                    padding: 0 1em;
                    margin-bottom: 1em;
                    img{
                        width:100%;
                        object-fit: contain;
                    }
                    .buttonsContainer{
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        h4{
                            flex:0.5;
                            text-align: center;
                            margin: 0;
                            font-size: 30px !important;
                        }
                        input{
                            flex: 1;
                            border: 1px solid black;
                            &:last-child{
                                &:disabled{
                                    border: 1px solid lightgrey;
                                }
                            }
                        }
                    }
                }
            }
            }
        }
        &.packages{
            display:flex;
            flex-direction: column;
            .packageItem{
                transition: .3s;
                box-sizing: border-box;
                padding:1em;
                order: 1;
                border-top: 1px solid black;
                .validation.message{
                    color: #b01f15 !important;
                    font-weight: bold;
                }
                &.onTop{
                    order: 0;
                }
                &.notAvailable{
                    order: 2;
                }
                &.sendtocart {
                    display:block;
                    animation: flytocart 1.5s;
                }
                // &:not(:first-child){
                // }
                &:hover{
                    border-radius: 10px;
                    transition: .3s;
                    box-shadow: 0 0 11px rgb(33 33 33);
                    background-color: rgba(255,255,255,0.3);
                    transform: scale(0.95);
                }
                .selectedImages{
                    display:flex;
                    .remainingImages{
                        display:flex;
                            flex-wrap: wrap;
                        flex-direction: column;

                        > section{
                            display:flex;
                            flex-wrap: wrap;
                            //width:100px;
                            &:nth-child(1){
                                img{
                                    width: 100px;
                                }
                            }
                            &:nth-child(2){
                                img{ width: 50px;}
                            }
                        }
                    }
                    .colourAndQuantityContainer{
                        display:flex;
                        flex-direction:column;
                        width: 15%;
                        margin-right: 1em;
                        .addPhoto{
                            font-size: 40px;
                            margin: 0 auto;
                        }
                        .addPhoto,
                        .notice{
                            cursor: pointer;
                        }
                        &:last-child{
                            margin-right:0;
                        }
                        .colourSelector{
                            display:flex;
                            justify-content: space-between;
                            .colourOption{
                                &:first-child{
                                    background: linear-gradient(180deg, rgba(0,14,255,1) 0%, rgba(255,0,0,1) 100%);
                                }
                                &:last-child{
                                    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(0,0,0,1) 100%);
                                }
                            }
                            .selected{
                                content: "✔"
                            }
                        }
                        .quantitySelector{
                            margin-right: auto;
                            input{
                                font-size: 16px;
                                &:focus{
                                    &::placeholder{
                                        color:transparent;
                                    }
                                }
                            }
                        }
                        img{width:100%;}
                    }
                }
                .title{
                    display:flex;
                    align-items:center;
                    width:100%;
                    justify-content:space-between;
                }
                .description{
                    display:flex;
                    flex-wrap:wrap;
                    width:100%;
                    text-align: left;
                    > p{
                        width:100%;
                        margin-bottom:0;
                    }

                    .select{
                        align-self: end;
                        margin-left: auto;
                    }
                }
            }
            .box{
                width:25px;
                height:25px;
                border:1px solid grey;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }
            .selected{
                border: 2px solid black;
            }
        }
    }
    > .instructions{
        text-align: left;
        list-style-position: inside;
    }
}

.checkout{
    // display:block;
    // float:left;
    display:flex;
    flex-wrap:wrap;
    width: 100%;
    // flex-wrap: wrap;
    //justify-content: space-evenly;
    h5{
        word-break: break-word;
        width: 100% !important;
        padding: 1em 0;
    }
    // FLOAT MIGHT BE BETTER HERE TBF
    .sidesContainer{
        display:flex;
        justify-content: space-around;
        flex-wrap: wrap-reverse;
    }
    .left,
    .right{
        flex: 0.75;
        //width: 45%;
        min-width: 300px;
        justify-content: space-between;
    }
    .checkoutItem{
        //display:grid;
        //grid-template-columns: auto 1fr auto;
         display:flex;
         flex-wrap:wrap;
        // width: 400px;
        // max-width: 500px;
        padding: 1em;
        border: 1px solid;
        margin-bottom: 1em;
        .checkoutItemHeader{
            display:flex;
            width:100%;
            padding-bottom: 6px;
            border-bottom: 1px solid black;
            align-items: center;
            h6, h5{
                width: auto;
                padding:0;
                margin-bottom:0;
            }
            h5{
                flex: 1;
            }
        }
        article{
            width: max-content;
        }
        &:last-child{
            &::after {
                content: "";
                flex: auto;
            }//margin-right: auto;
        }
    }
    h5,h6{
        text-align: left;
        width: max-content;
        &.price{
            font-weight: bold;
            // font-size: 1.2em !important;
        }
    }
    > p{
        width: max-content;
    }
    .bold{
        width:100%;
        margin-bottom: 0;
        text-align: left;
    }
    .items{
        display:flex;
        flex-wrap:wrap;
        padding: 6px 1em 0;
        height: max-content;
        width: max-content;//100%;
    }
    .packageDisplay{
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        // justify-content: space-between;
        width: max-content;
        max-width: 1200px;
        margin: 0 auto;
        padding-left: 1em;
        padding-top: 6px;
        box-sizing: border-box;
        > article{
            display:flex;
            flex-wrap:wrap;
            width: auto;
            // justify-content: space-evenly;
            //width: 400px;
            .packageImages{
                // width: 20%;
                text-align: left;
                // max-width:100px;
                > p{
                    margin-bottom: 0;
                }
                img{
                    // max-width: 100px;
                    max-width:100px;
                    &.bnw{
                        filter: grayscale(1)
                    }
                }
            }
        }
        p{
            width: auto;
            // max-width: 100px;
        }

    }
}

@keyframes xAxis {
    100% {
      transform: translateX(calc(50vw - 105px));
    }
  }

  @keyframes yAxis {
    100% {
      transform: translateY(calc(-50vh + 75px));
    }
  }

  @keyframes flytocart {
      10% {
          transform: translateY(-60%);
          width: 60%;
      }

      30% {
          transform: translate(400%, -100%);
          width: 20%;
          height: 10%;
          opacity: 0.3;
      }

      100% {
          transform: translate(550%, -171%);
          width: 5%;
          height: 5%;
          opacity: 0.1;
      }
  }

main.login{
    flex-direction: column;
    margin: 100px auto 0;
    form{
        display:flex;
        flex-direction: column;
        margin: auto;
        h4{
            margin-bottom: 50px;
        }
        textarea,
        input{
            background-color:transparent;
            border: none;
            //TODO - Rewrite this to be responsive with theme later
            border-bottom: 1px solid black;
            color:black;
            outline: none;
            text-align:center;
            margin-bottom:25px;
            font-size: 20px;

            
            &::placeholder{
                color: grey;
                text-align:center;
            }
            &[type="submit"]{
                // width: max-content;
                border: 1px solid black;
                margin-top: 50px;

                // Working
                // background: linear-gradient(to left, transparent 50%, white 50%) right;
                // background-size: 200%;
                // transition: .5s ease-out;

                // background:transparent;
                // background: linear-gradient(transparent,white);
                // background-size: 100% 200%;
                // background-repeat: repeat;
                // transition: .5s ease-out;
                &:hover{
                    // transform: translateY(-10px);
                    // transition: 300ms ease-out;
                    // background-position: 0 -102%;
                    // Working
                    // background-position: left;
                    // color: black;
                }
            }
        }
    }
}
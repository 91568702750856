main.portfolio{
    flex-direction: column;
    //padding: 100px 0 0;
    // .preloaderWrapper{
    //     margin-top: -100px;
    //     &.loaded{
    //         margin-top: 0;
    //     }
    // }
    > h2 {
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 1em;
    }
    .backButton{
        display:flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        svg{
            font-size: 40px;
            color:white;
        }
        p{
            margin: 0;
            font-size: 20px;
            padding-left:10px;
        }
    }
    .albumHeader {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        margin: 0 auto 10vh;
        text-align: center;
        //width: 80%;
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
            margin: 0 auto 5vh;
        }
        aside {
            @media only screen and (min-width: 720px) {
                width: 45%;
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
                width:80%;
                &:not(:first-child) {
                    margin-top: 5vh;
                }
            }
        }
        img{
            width: 100%;
        }
    }
    .albumPhotos{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        img{
            width: 30%;
        }
    }
    .albums{
        display:flex;
        flex-wrap:wrap;
        justify-content: space-between;
        width:80%;
        margin: 0 auto;
        // opacity: 0;
        // visibility: hidden;
        // -webkit-transition: opacity 600ms, visibility 600ms;
        // transition: opacity 600ms, visibility 600ms;
        .albumCover{
            border: 1px solid white;
            height: 300px;
            width: 300px;
            display:flex;
            margin: 0 auto 20px;
            background-size: cover;
            background-position: center;
            &:hover{
                cursor: pointer;
                section{
                    transition: 300ms;
                    background-color:rgba(0,0,0,.4);
                    p{
                        transition: 300ms;
                        transform: scale(1.2);
                    }
                }
            }
            section{
                background-color:rgba(0,0,0,.6);
                height: 100%;
                width: 100%;
                display:flex;
                transition: 300ms;
                p{
                    transition: 300ms;
                    margin: auto;
                    text-transform: capitalize;
                    font-size: 26px;
                }
            }
        }
    }
    .row{
        display:flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width:100%;
        margin: 0;
        overflow-x: hidden;
        & + input{
            width: max-content;
            margin: 0 auto 10vh;
        }
        .column{
            margin: 0 auto;
            min-width: 300px;
            width: 80%;
            @media only screen and (min-width: 720px) {
                width: 40%;
            }
            @media only screen and (min-width: 1200px) {
                width: 30%;
            }
            img{
                width: 100%;
                margin-bottom: 50px;
            }
        }
    }
    > p{
        margin-left: auto;
        margin-right: auto; //10%;
    }
}

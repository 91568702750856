.container{
    display:flex;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-around;

}
.listContainer{
    min-width: 15%;
    // min-width: 200px;
    list-style-type: none;
    margin-top: 1em;
}
.free{
    margin-top: -1em;
    margin-bottom:0;
    font-weight: bold;
}
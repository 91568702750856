.avatar{
    font-size: 100px;
}

.profile{
    flex-direction: row;
    width: 100%;
    padding-bottom: 1.5em;
    .accountContainer{
        display:flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 10px;
        // svg{
        //     color:rgba(255,255,255,0.5);
        // }
        section{
            svg{
                font-size:24px;
                margin: 0 auto;
            }
            a{
                text-align: left;
                display:grid;
                grid-template-columns: 50px auto;
                font-size: 20px;
                //justify-items: center;
                p{
                    margin-bottom: 0;
                    margin-right: auto;
                }
                margin-bottom:10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    .content{
        flex: 1;
        .tab{
            text-align: center;
            input{
                text-align: center;
            }

            .tableWrapper {
                width: 70%;
                margin: 1.5em auto 0 auto;
                .row:first-child {
                    p {
                        font-weight: bold;
                    }
                }
                .row {
                    justify-content: space-between;
                    margin: 0;
                    p span {
                        display: block;
                    }
                }
            }
        }
    }
}

.myProfile{
    display:flex;
    flex-wrap:wrap;
    max-width: 968px;
    > section{
        text-align: left;
        display:flex;
        flex-direction: column;
        flex:1;
        justify-content: flex-start;
        flex-wrap: wrap;
        min-width:300px;
        div{
            display:flex;
            flex-wrap: wrap;
            p:first-child{
                width: 130px;
            }
        }
        //max-width: 40%;
    }
}

.rowDirection{
    flex-direction: column;
}

.pageEdit{
    padding-top: 50px;
    textarea{
        width: 100%;
        margin-bottom: 0.5rem;
        &+ div{
            width: max-content;
            margin: 0 auto;
        }
    }
    form{
        justify-content: center;
        align-items: center;
        div{
            margin-bottom: 1em;
        }
    }
    select{
        text-transform: capitalize;
    }
}

.pageEditContentContainer{
    display:flex;
    justify-content: space-between;
    span{
        cursor: pointer;
    }
}
.admin{
    width: 100%;
}

.inputContainer{
    display:flex;
    flex-direction: column;
    label{
        margin-bottom: 0;
    }
}

.registration{
    margin-top: 40px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align:left;
    width: 300px;
    .username{
        text-align:left;
        cursor: not-allowed;
        color: grey;
    }
    div{
        width:100%;
        display:flex;
        align-items: center;
        border-bottom:1px solid black;
        svg{
            color:black;
            cursor: pointer;
        }
        input{
            width:100%;
            color:black;
            text-align:left;
            margin-bottom:0;
            border:none;
            &[type="submit"]{
                text-align: center;;
            }
        }
    }
    label{
        margin-top: 10px;
        color:black;
    }
}
.tokenContainer{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    width: 100%;
    h4{
        text-align: center;
    }
}
@import "./colours";

main.contact{
    // width: 80%;
    // // max-width: 1024px;
    // margin-left: auto;
    // margin-right: auto;
    flex-direction: column;
    .landingPage{
        margin-bottom: 10vh !important;
    }
    h4{
        text-align: center;
        width: 100%;
        margin-bottom: 5vh;
    }
    .contactDetails{
        display:flex;
        // flex-direction: column;
        justify-content:space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 90%;
        margin: 0 auto 10vh;
        max-width: 920px;
        @media only screen and (min-width: 1200px) {
            width: 60%;
        }
        & + .content{
            text-align: center;
            width:100%;
            > section{
                h4{
                    margin-bottom:0.5rem;
                }
                margin-bottom: 5vh;
            }
        }
        form{
            width: max-content;
            margin: 0 auto 5vh;
            label{
                color:grey;
            }
            section{
                display:flex;
                flex-direction:column;
                // box-sizing: border-box;
                // padding: 0 50px;
                width:100%;
                textarea,
                input{
                    width: 100%;
                    text-align: left;
                    &[type="submit"]{
                        text-align: center;
                        margin-top: 1em;
                    }
                }
                textarea{
                    min-height: 3em;
                    height:max-content;
                    resize: vertical;
                    max-height:10em;
                }
                &.name{
                    flex-direction:row;
                    aside{
                        display:flex;
                        flex-direction: column;
                        &:first-child{
                            margin-right: 10px;
                        }
                    }
                }

            }
        }
        .horiztontalSeparator {
            min-height: 40vh;
            // height: 50%;
            width: 1px;
            background-color: $grey;
            // display:none;
            @media only screen and (max-width: 820px) {
                display: none;
            }

            + article {
                color: $grey;
                font-size: 20px;
                // width:30%;
                // margin: auto;
                display: grid;
                height: max-content;
                margin: 0 auto;
                // grid-template-columns: 30% 70%;
                grid-template-rows: auto;

                a {
                    // display: flex;
                    display: grid;
                    width: 100%;
                    grid-template-columns: 40px auto;
                    list-style-type: none;
                    margin-bottom: 10px;
                    text-decoration: none !important;
                    border-bottom: 1px solid transparent;
                    transition: 300ms ease-out;
                    width: max-content;
                    p{
                        margin-bottom: 0;
                    }
                    &:hover {
                        border-color: white;
                        transition: 300ms ease-out;
                    }
                    svg {
                        font-size: 30px;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

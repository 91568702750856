.content{
    display:flex;
    flex-wrap:wrap;
    width: 100%;
    flex-direction:row;
    > section{
        flex: 1 1 300px;
        margin-bottom: 1em;
        //min-width: 300px;
        max-width: 480px;
        //height: 400px;
        //max-height: 600px;
        //overflow-y: scroll;
        //&:not(:first-child) {
        //    margin-top: 2.5em;
        //}
    }
}
/*.adminPanel {
    main.admin {
        div.titleContainer {
            padding: 1.5em 0 1.5em 0;
        }
    }

    table {
        td, th {
            padding-left: 1em;
        }

    }
}

.portal {
    height: unset !important;

    background-color: white !important;

    p {
        color: black !important;;
        text-shadow: none !important;

    }
}

.orderContent {

    display: flex;
    flex-direction: column;
}

.orderRow {
    display: flex;
    flex-direction: row;
    height: 20vh;
    margin-bottom: 1.5em;
}

article.photoPreview {
    height: 20vh;
    background-repeat: no-repeat;
    background-size: contain;
    width: auto;
    min-width: 20vw;
}
*/

.deliveryAddressForm{
    position: sticky;
    top: 100px;
    &, input.address{
        text-align: left !important;
    }
    &, section{
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;
        margin-left: auto;
        margin-right: auto;
    }
    section{
        width: 90%;
        max-width: 768px;
    }
    label{
        margin-bottom: 0;
    }
    .isMandatory{
        color: red;
    }
}

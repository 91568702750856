@import "../../css/colours";

@keyframes sacle-down {
    0% {
        transform: scale(1)
    }
    100% {
        transform: scale(0.8)
    }
}
@keyframes slide-down {
    0% {
        opacity: 1;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
        background: rgb(255,255,255);
        // background: rgba(0,0,0,0.8);
    }
}

.dropdownItems{
    display: none;
    position: absolute;
    // background-color: transparent;
    background-color: rgba(255,255,255,.8);;
    // min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 999;
    padding-top:20px;
    // margin-top:20px;
    text-align: left;
    padding: 1em;
    left: -1em;
    border: 1px solid #fff;
    &.scrolled{
        background: rgb(255,255,255);
    }
    a{
        margin-top:.5em;
    }
}

.dropdown:hover .dropdownItems {
    display: block;
}

header {
    padding: 1.5em 2em;
    box-sizing: border-box;
    // top: 0;
    width: 100%;
    transition: 300ms ease-in-out;
    // position: fixed;
    position: sticky;
    display: block;
    z-index: 100;
    background-color: #fff;
    color: black;

    &.scrolled {
        // transition: 300ms ease-in-out;
        // transition: height 300ms ease-in-out;
        // background-color: rgba(0,0,0,0.5);
        // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5018382352941176) 100%);
        // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        // background: linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 70%, rgba(0,0,0,0) 100%);
        // background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 70%, rgba(0,0,0,0) 100%);
        background: white;
        position: sticky;
        top: 0;
        // background: rgba(0,0,0,0.8);
        // transition: background 500ms;
        animation: slide-down 1s;
        z-index: 2;
        padding: 1em 2em;

        .left {
            > a > img {
                transform: scale(0.8);
                animation: scale-down 1s;
            }
        }
    }

    #navBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;

        .navbar-toggler {
            border-color: black;
            z-index: 1000000;
            color: black;

            span {
                filter: invert(1);
            }
        }

        .navbar-collapse {
            flex-grow: 0;

            &.show .right {
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: white;
                flex-direction: column;
                text-align: center;
                justify-content: center;
                align-items: center;
                // overflow-y: scroll;
                svg {
                    display: none;
                }

                > a,
                .nonClickable {
                    font-weight: bold;
                }

                .dropdownItems {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    text-align: center;
                    left: 0;
                    border: 0;
                    margin-right: 0;
                    box-shadow: none;
                }
            }
        }

        a, p {
            color: black;
        }

        .left {
            order: 0;
            z-index: 100;

            > a {
                &::before {
                    height: 0;
                }

                > img {
                    // width: 100%;
                    margin: 0 auto;
                    // filter: invert(1);
                    max-width: 120px;
                    max-height: 60px;
                    width: auto;
                    // transform: scale(1);
                    transition: 1s;
                }
            }
        }

        .navbar-collapse.show .right {
            margin-left: 0;
            overflow-x: hidden;
            width: 100vw;
        }

        .right {
            order: 1;
            text-align: right;
            display: flex;
            flex-wrap: wrap;
            margin-left: 1em;
            @media only screen and (max-width: 480px) {
                margin-left: 0;
                overflow-x: hidden;
                overflow-y: scroll;
                width: 100vw;
            }

            a {
                margin-right: 1em;

                &.last-child {
                    margin-right: 0;
                }

                svg {
                    font-size: 16px;
                    margin-left: .5em;
                }
            }

            p {
                font-size: 1.5em;
                margin: 0 1em;
            }

            span {
                margin-right: 1em;
            }

            .nonClickable::before {
                height: 0;
            }
        }

        a {
            // font-size: 1.5em;
            font-size: 18px;
            // font-weight: bold;
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            -webkit-box-shadow: 0 0 1px rgb(0 0 0 / 0%);
            box-shadow: 0 0 1px rgb(0 0 0 / 0%);
            position: relative;
            overflow: hidden;
            // text-transform: uppercase;
            &:hover {
                text-decoration: none;
            }

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 51%;
                right: 51%;
                bottom: 0;
                background: black; //#710003;
                height: 1px;
                -webkit-transition-property: left, right;
                transition-property: left, right;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }

            &:hover:before, &:focus:before, &:active:before {
                left: .5em;
                right: .5em;
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }

            &.important {
                color: #FF4136;
                font-weight: bold;
            }
        }
    }
}

main.about{
    flex-direction: column;
    .landingPage{
        height: 60vh;
        background-position: center center;
        .subNavigation p{
            color: white !important;
        }
    }
    .description{
        width: 80%;
        max-width: 720px;
        @media only screen and (min-width: 720px) {
            width: 60%;
        }
        margin: 0 auto;
        text-align:center;
        margin-bottom: 20vh;
        p{
            line-height: 2em;
        }
    }
}